:root {
    --theme-blue: #3083CA;
    --theme-green: #7D945D;
    --theme-orange: #BA6F44;
    --theme-wooden: #83593F;
    --theme-black: #757575;
    --app-dark: rgb(0, 3, 36);
}

.App {
    min-height: 100vh;
}
.App .bg-circles {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
}
.App .bg-circle {
    position: absolute;
    /*right: 15%;
    top: 50px;*/
    right: -30px;
    bottom: 0;
    border-radius: 50%;
    box-shadow: 54px 24px 39px 0px rgba(0, 0, 0, 0.02);
}
.App .container-fluid {
    min-height: 100vh;
}

.App.dark-layout {
    background-color: var(--app-dark);
}

.app-theme-icon {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
    z-index: 100;
}

.outer-circle {
    width: 800px;
    height: 800px;
    animation: ripple1 15s infinite ease-in-out;
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.09) 0%, rgba(255, 255, 255, 0.05) 100%);
}
.inner-circle {
    width: 650px;
    height: 650px;
    animation: ripple2 15s infinite ease-in-out;
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.09) 100%);
}
.App.light-layout .outer-circle {
    background-image: linear-gradient(180deg, rgba(150, 150, 150, 0.09) 0%, rgba(150, 150, 150, 0.05) 100%);
}
.App.light-layout .inner-circle {
    background-image: linear-gradient(180deg, rgba(150, 150, 150, 0.12) 0%, rgba(150, 150, 150, 0.09) 100%);
}
@keyframes ripple2 {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.05);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes ripple1 {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.15);
  }
  50% {
    transform: scale(1.1);
  }
  75% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}

.bg-squares {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 35px;
}

.bg-square {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.25);
}
.App.light-layout .bg-square {
    background: rgba(57, 35, 174, 0.45);
}

.bg-square-1 {
    rotate: 35deg;
    position: absolute;
    top: calc(100vw - 65vw);
    left: 75px;
}

.bg-square-2 {
    width: 75px;
    height: 75px;
    rotate: 65deg;
    position: absolute;
    top: calc(100vw - 80vw);
    left: 145px;
    background: rgba(255, 255, 255, 0.35);
}
.App.light-layout .bg-square-2 {
    background: rgba(203, 109, 215, 0.45);
}

.bg-square-3 {
    width: 90px;
    height: 90px;
    rotate: 15deg;
    position: absolute;
    top: calc(100vw - 95vw);
    left: 100px;
    background: rgba(255, 255, 255, 0.20);
}

.app-container {
    position: relative;
    z-index: 9;
}

body {
    background: #F6F4FE;
}

.top-bar {
    max-width: calc(100vh - 100px);
    margin: auto;
}
#chess-board {
    display: flex;
    flex-wrap: wrap;
    max-width: calc(100vh - 100px);
    aspect-ratio: 1/1;
    border: 1px solid #CCC;
    margin: auto;
}
.chess-square {
    width: 12.5%;
    height: 12.5%;
    text-align: center;
    background: #BA6F44;
}
.chess-square.square-light {
    background: #FEFEEE;
}
.chess-square.has-piece {
    cursor: pointer;
}
.chess-square.active {
    background: #F8F799 !important;
}
.chess-square.movable-position {
    cursor: pointer;
    background: radial-gradient(#D6E2F0 20%, #ACC9F2 80%) !important;
}
.chess-square.castling-position {
    cursor: pointer;
    background: radial-gradient(#D6E2F0 20%, #4DD0E1 80%) !important;
}
.chess-square.capturable-position,
.chess-square.enpassant,
.chess-square.in-check {
    cursor: pointer;
    background: radial-gradient(#D6E2F0 20%, #F44336 80%) !important;
}
.chess-square img {
    width: 95%;
}
.modal-backdrop {
    background-color: rgba(0, 0, 0, 0.5);
}
.modal-header {
    background-color: #BA6F44;
}
.modal-title {
    font-size: 20px;
    color: #FFF;
}
.btn {
    min-width: 95px;
}
.btn-primary {
    color: #fff;
    font-weight: 600;
    background-color: #BA6F44;
    border-color: #BA6F44;
}
.btn-primary:hover {
    opacity: 0.9;
    background-color: #BA6F44;
    border-color: #BA6F44;
}
.btn:disabled {
    background-color: #BA6F44;
    border-color: #BA6F44;
}

.promote-piece {
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid transparent;
}
.promote-piece.selected,
.promote-piece:hover {
    background-color: #F3F3F3;
    border-color: #7D945D;
}
.promote-piece img {
    max-width: 150px;
}

.app-row {
    position: relative;
    overflow-x: hidden;
    justify-content: center;
}
.app-row > .col-md-9 {
    width: 100vh;
    z-index: 5;
}
.app-row > .col-md-3 {
    max-width: calc(100vh / 2.5);
/*    min-width: 350px;*/
}

.captured-pieces img {
    max-height: 50px;
}
.single-move:not(:last-child) {
    border-bottom: 1px solid #3083CA;
}
.single-move:nth-child(even) {
    background: rgba(220, 220, 220, 0.1);
}
.move-notations .card-body {
    overflow-y: auto;
}

.game-info-sidebar .card {
    border: 1px solid;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.game-info-sidebar .card .card-header {
    color: #FFF;
}
.game-info-sidebar .card .card-header h3 {
    font-size: 1.25rem;
}

.demo-info.card {
    border-top-right-radius: 35px;
    border-bottom-left-radius: 35px;
    /*background: linear-gradient(170deg, #01E4F8 0%, #1D3EDE 100%);
    background: linear-gradient(170deg, #B4EC51 0%, #429321 100%);*/
    background: linear-gradient(170deg, #C86DD7 0%, #3023AE 100%);
    border-color: transparent;
}
.demo-info.card .card-header {
    border-top-right-radius: 35px;
}
.demo-info.card .card-header h3 {
    font-size: 1.3rem;
    font-weight: 600;
}

.demo-info.card .card-header,
.demo-info.card .card-body {
    color: #FFF;
    border-color: rgba(255, 255, 255, 0.35) !important;
    background-color: transparent !important;
    font-weight: 600;
}

.mobile-top-section {
    display: none;
}
.desktop-logo {
    position: absolute;
    bottom: 10px;
    right: 15px;
    z-index: 10;
}

/* Theme */
.theme-control {
    display: inline-flex;
    width: 30px;
    height: 30px;
    margin: 0 3px;
    cursor: pointer;
    justify-content: center;
}
.theme-control.green {
    background: var(--theme-green);
}
.theme-control.blue {
    background: var(--theme-blue);
}
.theme-control.orange {
    background: var(--theme-orange);
}
.theme-control.wooden {
    background: var(--theme-wooden);
}
.theme-control.black {
    background: var(--theme-black);
}
.theme-control img {
    display: none;
}
.theme-control.active img {
    display: inline;
}

/* Chess Square */
#chess-board.orange .chess-square {
    background: var(--theme-orange);
}
#chess-board.orange .chess-square.square-light {
    background: #FEFEEE;
}
#chess-board.blue .chess-square {
    background: var(--theme-blue);
}
#chess-board.blue .chess-square.square-light {
    background: #FEFEEE;
}
#chess-board.green .chess-square {
    background: var(--theme-green);
}
#chess-board.green .chess-square.square-light {
    background: #FEFEEE;
}
#chess-board.wooden .chess-square {
    background: var(--theme-wooden);
}
#chess-board.wooden .chess-square.square-light {
    background: #E7C795;
}
#chess-board.black .chess-square {
    background: var(--theme-black);
}
#chess-board.black .chess-square.square-light {
    background: #FFF;
}

/* Modal & Card */
.modal-orange .modal-header,
.card-orange .card-header {
    background-color: var(--theme-orange);
}
.modal-blue .modal-header,
.card-blue .card-header {
    background-color: var(--theme-blue);
}
.modal-green .modal-header,
.card-green .card-header {
    background-color: var(--theme-green);
}
.modal-wooden .modal-header,
.card-wooden .card-header {
    background-color: var(--theme-wooden);
}
.modal-black .modal-header,
.card-black .card-header {
    background-color: var(--theme-black);
}
.card-orange {
    border-color: var(--theme-orange) !important;
}
.card-blue {
    border-color: var(--theme-blue) !important;
}
.card-green {
    border-color: var(--theme-green) !important;
}
.card-wooden {
    border-color: var(--theme-wooden) !important;
}
.card-black {
    border-color: var(--theme-black) !important;
}

/* Button */
.btn-orange,
.btn-orange:disabled {
    background-color: var(--theme-orange);
    border-color: var(--theme-orange);
}
.btn-orange:hover {
    background-color: var(--theme-orange);
}
.btn-blue,
.btn-blue:disabled {
    background-color: var(--theme-blue);
    border-color: var(--theme-blue);
}
.btn-blue:hover {
    background-color: var(--theme-blue);
}
.btn-green,
.btn-green:disabled {
    background-color: var(--theme-green);
    border-color: var(--theme-green);
}
.btn-green:hover {
    background-color: var(--theme-green);
}
.btn-wooden,
.btn-wooden:disabled {
    background-color: var(--theme-wooden);
    border-color: var(--theme-wooden);
}
.btn-wooden:hover {
    background-color: var(--theme-wooden);
}
.btn-black,
.btn-black:disabled {
    background-color: var(--theme-black);
    border-color: var(--theme-black);
}
.btn-black:hover {
    background-color: var(--theme-black);
}

@media (max-width: 1340px) and (min-width: 992px) {
    .app-container {
        padding-right: 12px !important;
    }
    .app-row > .col-md-9 {
        width: calc(100vw - 400px);
    }
}

@media (max-width: 1200px) and (min-width: 992px) {
    .App {
        display: flex;
        align-items: center;
    }
}

@media (max-width: 992px) {
    .desktop-logo {
        display: none;
    }
    .mobile-top-section {
        display: block;
    }
    .App {
        padding-top: 36px;
    }
    .app-container {
        max-width: 750px;
        margin: auto;
    }
    #chess-board {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .game-info-sidebar {
        margin-top: 20px;
        width: 90%;
        max-width: 90% !important;
    }
    .game-info-sidebar .demo-info {
        display: none;
    }
    .bg-square-2 {
        top: 35px;
        left: 85%;
    }
    /*#chess-board {
        max-width: calc(100vw - 80px);
        margin: unset;
    }
    .game-info-sidebar {
        position: absolute;
        width: 350px;
        right: -350px;
        z-index: 9;
        background: var(--app-dark);
        transition: right 0.5s ease-out;
    }
    .game-info-sidebar.opened {
        right: 0;
    }
    .mobile-sidebar-btn {
        position: absolute;
        background: #C86DD7;
        left: -50px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .mobile-sidebar-btn svg {
        fill: #FFF;
    }*/
}

@media (max-width: 576px) {
    .bg-square-3 {
        width: 75px;
        height: 75px;
        left: -10px;
        top: -10px;
    }
}